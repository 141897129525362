import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig'; 
import { collection, addDoc, getDocs, deleteDoc, doc } from 'firebase/firestore'; 
import AddTournamentPopup from './AddTournamentPopup'; 
import ViewTournamentsPopup from './ViewTournamentsPopup'; 
import AddNewsPopup from './AddNewsPopup'; 
import ManageTeamsPopup from './ManageTeamsPopup';  // Import the Manage Teams popup component
import './AdminPanel.css'; 
import { FaTrashAlt, FaEdit } from 'react-icons/fa';  // Icons for Edit and Delete

function AdminPanel() {
  const [showAddTournamentPopup, setShowAddTournamentPopup] = useState(false);
  const [showViewTournamentsPopup, setShowViewTournamentsPopup] = useState(false);
  const [showAddNewsPopup, setShowAddNewsPopup] = useState(false);
  const [showManageTeamsPopup, setShowManageTeamsPopup] = useState(false); // State for Manage Teams popup
  const [tournaments, setTournaments] = useState([]);
  const [teams, setTeams] = useState([]); // State for teams

  // Fetch tournaments from Firestore
  const fetchTournaments = async () => {
    const tournamentsCollection = collection(db, 'Tournaments');
    const tournamentSnapshot = await getDocs(tournamentsCollection);
    const tournamentList = tournamentSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setTournaments(tournamentList);
  };

  // Fetch teams from Firestore
  const fetchTeams = async () => {
    const teamsCollection = collection(db, 'teams'); // Make sure this matches your Firestore collection name
    const teamsSnapshot = await getDocs(teamsCollection);
    const teamsList = teamsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setTeams(teamsList);
  };

  const handleDeleteTournament = async (id) => {
    const tournamentRef = doc(db, 'Tournaments', id);
    await deleteDoc(tournamentRef);
    fetchTournaments(); 
  };

  useEffect(() => {
    fetchTournaments(); 
    fetchTeams(); // Fetch teams when the component mounts
  }, []);

  return (
    <div className="admin-panel">
      <h2>Admin Panel</h2>
      <strong>Make Sure You Reload The Page Beofre Using a Function</strong>
      <div className="button-grid">
        <button className="admin-button" onClick={() => setShowAddTournamentPopup(true)}>
          Add Tournament
        </button>
        <button className="admin-button" onClick={() => setShowViewTournamentsPopup(true)}>
          View Current Tournaments
        </button>
        <button className="admin-button" onClick={() => setShowAddNewsPopup(true)}> 
          Add News
        </button>
        <button className="admin-button" onClick={() => setShowManageTeamsPopup(true)}>
          Manage Teams
        </button> {/* New Manage Teams Button */}
        <button className="admin-button" onClick={() => window.location.href = '/overlay-admin'}>
          Overlay
        </button>
        <button className="admin-button" onClick={() => window.location.href = '/mini-overlay-admin'}>
          Mini Overlay
        </button>

      </div>

      {showAddTournamentPopup && (
        <AddTournamentPopup onClose={() => setShowAddTournamentPopup(false)} onAdd={fetchTournaments} />
      )}

      {showViewTournamentsPopup && (
        <ViewTournamentsPopup tournaments={tournaments} onClose={() => setShowViewTournamentsPopup(false)} onDelete={handleDeleteTournament} />
      )}

      {showAddNewsPopup && ( 
        <AddNewsPopup onClose={() => setShowAddNewsPopup(false)} />
      )}

      {showManageTeamsPopup && (  // Conditionally render ManageTeamsPopup
        <ManageTeamsPopup teams={teams} onClose={() => setShowManageTeamsPopup(false)} />
      )}
    </div>
  );
}

export default AdminPanel;
