import React from "react";
import MiniOverlayScoreboard from "./MiniOverlayScoreboard";
import "./MiniOverlay.css";

const MiniOverlay = () => {
  return (
    <div className="mini-overlay-display">
      <MiniOverlayScoreboard overlayId="miniOverlay1" />
      <MiniOverlayScoreboard overlayId="miniOverlay2" />
    </div>
  );
};

export default MiniOverlay;
