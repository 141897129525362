import React, { useState, useEffect } from "react";
import { db } from "../firebaseConfig";
import { doc, setDoc, getDocs, collection } from "firebase/firestore";
import "./MiniOverlayAdminPanel.css";

const MiniOverlayAdminPanel = ({ overlayId }) => {
  const [teams, setTeams] = useState([]);
  const [maps] = useState(["Abyss", "Ascent", "Bind", "Breeze", "Fracture", "Haven", "Icebox", "Lotus", "Pearl", "Split", "Sunset"]);
  const [overlayData, setOverlayData] = useState({
    attackTeamName: "",
    attackTeamLogo: "",
    attackRounds: 0,
    mapName: "",
    defendRounds: 0,
    defendTeamName: "",
    defendTeamLogo: "",
    isLive: false,
  });

  useEffect(() => {
    const fetchTeams = async () => {
      const querySnapshot = await getDocs(collection(db, "teams"));
      const teamData = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setTeams(teamData);
    };
    fetchTeams();
  }, []);

  const handleInputChange = (field, value) => {
    const updatedData = { ...overlayData, [field]: value };
    setOverlayData(updatedData);
    setDoc(doc(db, "miniOverlays", overlayId), updatedData, { merge: true });
  };

  const handleLiveCheckboxChange = (e) => {
    const updatedData = { ...overlayData, isLive: e.target.checked };
    setOverlayData(updatedData);
    setDoc(doc(db, "miniOverlays", overlayId), updatedData, { merge: true });
  };

  const handleTeamChange = (teamType, teamName) => {
    const selectedTeam = teams.find((team) => team.teamName === teamName);
    if (selectedTeam) {
      const updatedData = {
        ...overlayData,
        [`${teamType}TeamName`]: selectedTeam.teamName,
        [`${teamType}TeamLogo`]: selectedTeam.teamLogo,
      };
      setOverlayData(updatedData); // Update local state
      setDoc(doc(db, "miniOverlays", overlayId), updatedData, { merge: true }); // Update Firestore
    }
  };
  

  return (
    <div className="minioverlayadminpanel">
      <h3>Control Panel for {overlayId}</h3>
      
      <div className="overlay-row">
        <div className="control-panel">
          <div className="control-column">
            <div className="live-checkbox">
              <label>
                Match Live:
                <input
                  type="checkbox"
                  checked={overlayData.isLive}
                  onChange={handleLiveCheckboxChange}
                />
              </label>
            </div>
            <div className="map-dropdown">
              <label>Map:</label>
              <select
                value={overlayData.mapName}
                onChange={(e) => handleInputChange("mapName", e.target.value)}
              >
                <option value="">Select Map</option>
                {maps.map((map, index) => (
                  <option key={index} value={map}>
                    {map}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="control-column">
            <div className="team-selection">
              <div className="team-field">
                <label>Attack Team:</label>
                <select
                  value={overlayData.attackTeamName}
                  onChange={(e) => handleTeamChange("attack", e.target.value)}
                >
                  <option value="">Select Attack Team</option>
                  {teams.map((team) => (
                    <option key={team.id} value={team.teamName}>
                      {team.teamName}
                    </option>
                  ))}
                </select>
              </div>

              <div className="team-field">
                <label>Defend Team:</label>
                <select
                  value={overlayData.defendTeamName}
                  onChange={(e) => handleTeamChange("defend", e.target.value)}
                >
                  <option value="">Select Defend Team</option>
                  {teams.map((team) => (
                    <option key={team.id} value={team.teamName}>
                      {team.teamName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="control-column">
            <div className="round-controls">
              <div className="round-field">
                <label>Attack Rounds:</label>
                <input
                  type="number"
                  value={overlayData.attackRounds}
                  onChange={(e) => handleInputChange("attackRounds", parseInt(e.target.value))}
                />
              </div>

              <div className="round-field">
                <label>Defend Rounds:</label>
                <input
                  type="number"
                  value={overlayData.defendRounds}
                  onChange={(e) => handleInputChange("defendRounds", parseInt(e.target.value))}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MiniOverlayAdminPanel;
