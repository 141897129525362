import React, { useEffect, useState } from "react";
import { db } from "../firebaseConfig";
import { doc, onSnapshot } from "firebase/firestore";
import "./MiniOverlayScoreboard.css";

const MiniOverlayScoreboard = ({ overlayId }) => {
  const [overlayData, setOverlayData] = useState(null);

  useEffect(() => {
    const unsubscribe = onSnapshot(doc(db, "miniOverlays", overlayId), (doc) => {
      setOverlayData(doc.data());
    });

    return () => unsubscribe();
  }, [overlayId]);

  if (!overlayData || !overlayData.isLive) return null; // Don't render if not live

  return (
    <div className="mini-overlay-scoreboard-mini">
      <div className="scoreboard-section-mini">
        <div className="team-mini attack-team-mini">
          <div className="team-content-mini">
            <div className="team-logo-mini">
              <img src={overlayData.attackTeamLogo} alt="Attack Team Logo" />
            </div>
            <div className="team-name-mini">
              <span>{overlayData.attackTeamName}</span>
            </div>
            <div className="team-rounds-mini">
              <span>{overlayData.attackRounds}</span>
            </div>
          </div>
        </div>
        <div className="map-info-mini">
          <div className="map-name-mini">
            <span>{overlayData.mapName}</span>
          </div>
          <div className="vs-mini">
            <span>VS</span>
          </div>
        </div>
        <div className="team-mini defend-team-mini">
          <div className="team-content-mini">
            <div className="team-rounds-mini">
              <span>{overlayData.defendRounds}</span>
            </div>
            <div className="team-name-mini">
              <span>{overlayData.defendTeamName}</span>
            </div>
            <div className="team-logo-mini">
              <img src={overlayData.defendTeamLogo} alt="Defend Team Logo" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MiniOverlayScoreboard;
