import React from "react";
import MiniOverlayAdminPanel from "./MiniOverlayAdminPanel";
import MiniOverlayScoreboard from "./MiniOverlayScoreboard";
import "./MiniOverlayAdmin.css";
import "./MiniOverlayScoreboard.css"; // Ensure CSS is scoped

const MiniOverlayAdmin = () => {
  return (
    <div className="mini-overlay-admin">
      <h1>Mini Overlay Admin</h1>
      <div className="mini-overlays">
        <div className="mini-overlay-wrapper">
          <MiniOverlayScoreboard overlayId="miniOverlay1" />
          <MiniOverlayAdminPanel overlayId="miniOverlay1" />
        </div>
        <div className="mini-overlay-wrapper">
          <MiniOverlayScoreboard overlayId="miniOverlay2" />
          <MiniOverlayAdminPanel overlayId="miniOverlay2" />
        </div>
      </div>
    </div>
  );
};

export default MiniOverlayAdmin;
