import React, { useState, useEffect } from "react";
import { db } from "../firebaseConfig";
import { collection, doc, setDoc, getDocs, onSnapshot } from "firebase/firestore";
import "./OverlayControlPanel.css";
import OverlayDisplay from './OverlayDisplay';

const mapOptions = ["Abyss", "Ascent", "Bind", "Breeze", "Fracture", "Haven", "Icebox", "Lotus", "Pearl", "Split", "Sunset"];
const agentOptions = ["Astra", "Breach", "Brimstone", "Chamber", "Cypher", "Fade", "Harbor", "Jett", "KAYO", "Killjoy", "Neon", "Omen", "Phoenix", "Raze", "Reyna", "Sage", "Skye", "Sova", "Viper", "Yoru", "Gekko", "Deadlock", "Iso", "Clove", "Vyse"];

const OverlayControlPanel = () => {
  const [teams, setTeams] = useState([]);
  const [attackTeam, setAttackTeam] = useState({ name: '', rounds: 0, players: [] });
  const [defendTeam, setDefendTeam] = useState({ name: '', rounds: 0, players: [] });
  const [maps, setMaps] = useState([{ status: '', name: '' }, { status: '', name: '' }, { status: '', name: '' }]);
  const [isSwitched, setIsSwitched] = useState(false);
  const [attackLabel, setAttackLabel] = useState("Attack Team");
  const [defendLabel, setDefendLabel] = useState("Defend Team");
const [attackTeamPlaceholder, setAttackTeamPlaceholder] = useState(attackTeam.teamName || "Select Attack Team");
const [defendTeamPlaceholder, setDefendTeamPlaceholder] = useState(defendTeam.teamName || "Select Defend Team");


  useEffect(() => {
    const fetchTeams = async () => {
      const querySnapshot = await getDocs(collection(db, "teams"));
      const teamData = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setTeams(teamData);
    };
    fetchTeams();

    // Set up real-time listeners
    const unsubscribeMaps = onSnapshot(doc(db, "overlay", "maps"), (doc) => {
      const data = doc.data();
      setMaps([
        { status: data.map1status, name: data.map1name },
        { status: data.map2status, name: data.map2name },
        { status: data.map3status, name: data.map3name },
      ]);
    });

    const unsubscribeAttack = onSnapshot(doc(db, "overlay", "attack"), (doc) => {
      setAttackTeam(doc.data());
    });

    const unsubscribeDefend = onSnapshot(doc(db, "overlay", "defend"), (doc) => {
      setDefendTeam(doc.data());
    });

    return () => {
      // Clean up listeners when the component unmounts
      unsubscribeMaps();
      unsubscribeAttack();
      unsubscribeDefend();
    };
  }, []);

  const handleMapChange = (index, field, value) => {
    const newMaps = [...maps];
    newMaps[index][field] = value;
    setMaps(newMaps);
    setDoc(doc(db, "overlay", "maps"), { [`map${index + 1}${field}`]: value }, { merge: true });
  };

  const handleTeamChange = (team, field, value) => {
    const updatedTeam = { ...team, [field]: value };
    if (team === attackTeam) {
      setAttackTeam(updatedTeam);
      setDoc(doc(db, "overlay", "attack"), updatedTeam, { merge: true });
    } else {
      setDefendTeam(updatedTeam);
      setDoc(doc(db, "overlay", "defend"), updatedTeam, { merge: true });
    }
  };

  const handleTeamSelect = async (teamType, teamId) => {
    const selectedTeam = teams.find((team) => team.id === teamId);
    const playerData = selectedTeam.players.map(player => ({
      name: player.riotId.split("#")[0],
      agent: "",
      KDA: { kills: 0, deaths: 0, assists: 0 },
      isdead: false,
    }));

    const teamData = {
      teamName: selectedTeam.teamName,
      teamLogo: selectedTeam.teamLogo,
      rounds: 0,
      players: playerData,
    };

    if (teamType === "attack") {
      setAttackTeam(teamData);
      setDoc(doc(db, "overlay", "attack"), teamData, { merge: true });
    } else {
      setDefendTeam(teamData);
      setDoc(doc(db, "overlay", "defend"), teamData, { merge: true });
    }
  };

  const handlePlayerChange = (teamType, playerIndex, field, value) => {
    const team = teamType === "attack" ? { ...attackTeam } : { ...defendTeam };
    const updatedPlayer = { ...team.players[playerIndex] };

    if (field === "kills" || field === "deaths" || field === "assists") {
      updatedPlayer.KDA = { ...updatedPlayer.KDA, [field]: parseInt(value) || 0 };
    } else {
      updatedPlayer[field] = value;
    }

    const updatedPlayers = [...team.players];
    updatedPlayers[playerIndex] = updatedPlayer;
    const updatedTeam = { ...team, players: updatedPlayers };

    if (teamType === "attack") {
      setAttackTeam(updatedTeam);
      setDoc(doc(db, "overlay", "attack"), updatedTeam, { merge: true });
    } else {
      setDefendTeam(updatedTeam);
      setDoc(doc(db, "overlay", "defend"), updatedTeam, { merge: true });
    }
  };

  // Reset all values in agent, KDA, isdead, and rounds
  const resetAllValues = () => {
    const resetTeamData = (team) => ({
      ...team,
      rounds: 0,
      players: team.players.map(player => ({
        ...player,
        agent: "",
        KDA: { kills: 0, deaths: 0, assists: 0 },
        isdead: false,
      })),
    });
    setAttackTeam(resetTeamData(attackTeam));
    setDefendTeam(resetTeamData(defendTeam));
    setDoc(doc(db, "overlay", "attack"), resetTeamData(attackTeam), { merge: true });
    setDoc(doc(db, "overlay", "defend"), resetTeamData(defendTeam), { merge: true });
  };

  // Reset only the isdead status to false
  const newRoundReset = () => {
    const resetIsDeadStatus = (team) => ({
      ...team,
      players: team.players.map(player => ({ ...player, isdead: false })),
    });
    setAttackTeam(resetIsDeadStatus(attackTeam));
    setDefendTeam(resetIsDeadStatus(defendTeam));
    setDoc(doc(db, "overlay", "attack"), resetIsDeadStatus(attackTeam), { merge: true });
    setDoc(doc(db, "overlay", "defend"), resetIsDeadStatus(defendTeam), { merge: true });
  };

// Switch sides logic
const switchSides = () => {
  setIsSwitched(prevState => !prevState);
  setDoc(doc(db, "overlay", "maps"), { isSwapped: !isSwitched }, { merge: true });

  // Swap teams and their labels
  const swappedAttackTeam = { ...defendTeam };
  const swappedDefendTeam = { ...attackTeam };
  
  // Update the state for attack and defend teams
  setAttackTeam(swappedAttackTeam);
  setDefendTeam(swappedDefendTeam);

  // Update Firestore with the swapped teams
  setDoc(doc(db, "overlay", "attack"), swappedAttackTeam, { merge: true });
  setDoc(doc(db, "overlay", "defend"), swappedDefendTeam, { merge: true });

  // Optionally, update the placeholder state if needed
  setAttackTeamPlaceholder(swappedDefendTeam.teamName);
  setDefendTeamPlaceholder(swappedAttackTeam.teamName);
};


  return (
    <div className="overlay-control-panel">
            <OverlayDisplay 
        attackTeam={attackTeam} 
        defendTeam={defendTeam} 
        mapSelections={maps} 
        isSwitched={isSwitched} 
      />
      <div className="control-panel-wrapper">
        <div className="reset-controls" style={{ textAlign: "center", marginBottom: "15px" }}>
          <button onClick={newRoundReset}>New Round</button>
          <button onClick={switchSides} className="switch-sides-btn">Swap Teams</button>
          <button onClick={resetAllValues}>Reset (NEW MATCH)</button>
        </div>
        <div className="map-selection">
          {maps.map((map, index) => (
            <div key={index} className="map-controls">
              <label>Map {index + 1}</label>
              <select value={map.status} onChange={(e) => handleMapChange(index, "status", e.target.value)}>
                <option value="">Select Status</option>
                <option value="Current">Current</option>
                <option value="Next">Next</option>
                <option value="Decider">Decider</option>
                <option value="Finish">Finished</option>
              </select>
              <select value={map.name} onChange={(e) => handleMapChange(index, "name", e.target.value)}>
                <option value="">Select Map</option>
                {mapOptions.map((mapName) => (
                  <option key={mapName} value={mapName}>{mapName}</option>
                ))}
              </select>
            </div>
          ))}
        </div>
        <div className="team-selection-container">
          {["attack", "defend"].map((teamType) => (
            <div key={teamType} className="team-section">
              <h3>{teamType === "attack" ? attackLabel : defendLabel}</h3>
              <select
                onChange={(e) => handleTeamSelect(teamType, e.target.value)}
                defaultValue=""
              >
                <option value="" disabled>
                  {teamType === "attack" ? attackTeam.teamName || "Select Attack Team" : defendTeam.teamName || "Select Defend Team"}
                </option>
                {teams.map((team) => (
                  <option key={team.id} value={team.id}>
                    {team.teamName}
                  </option>
                ))}
              </select>
              <div className="round-input">
                <label>Rounds</label>
                <input
                  type="number"
                  value={teamType === "attack" ? attackTeam.rounds : defendTeam.rounds}
                  onChange={(e) => handleTeamChange(teamType === "attack" ? attackTeam : defendTeam, "rounds", e.target.value)}
                />
              </div>
              <div className="players-info">
                  {(teamType === "attack" ? attackTeam : defendTeam).players.map((player, idx) => (
                    <div key={idx} className="player-info-overlay">
                      <div className="player-name-overlay">
                        <strong>{player.name}</strong>
                      </div>
                      <div className="agent-info">
                        <select
                          value={player.agent}
                          onChange={(e) => handlePlayerChange(teamType, idx, "agent", e.target.value)}
                        >
                          <option value="">Select Agent</option>
                          {agentOptions.map((agent) => (
                            <option key={agent} value={agent}>{agent}</option>
                          ))}
                        </select>
                      </div>
                      <div className="kda-info">
                        <div className="kda-row">
                          <div className="kda-column">
                            <label>Kills</label>
                            <input
                              type="number"
                              value={player.KDA.kills}
                              onChange={(e) => handlePlayerChange(teamType, idx, "kills", e.target.value)}
                            />
                          </div>
                          <div className="kda-column">
                            <label>Deaths</label>
                            <input
                              type="number"
                              value={player.KDA.deaths}
                              onChange={(e) => handlePlayerChange(teamType, idx, "deaths", e.target.value)}
                            />
                          </div>
                          <div className="kda-column">
                            <label>Assists</label>
                            <input
                              type="number"
                              value={player.KDA.assists}
                              onChange={(e) => handlePlayerChange(teamType, idx, "assists", e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="isdead-row">
                          <label>DEAD: </label>
                          <input
                            type="checkbox"
                            checked={player.isdead}
                            onChange={(e) => handlePlayerChange(teamType, idx, "isdead", e.target.checked)}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OverlayControlPanel;
